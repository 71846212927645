









import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import RangeFilter from "./RangeFilter.vue";
import {
  EventOperation,
  FilterId,
  NumbersFilterModel,
  RangeModel,
} from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({
  components: {
    RangeFilter,
  },
})
export default class UserAgeFilter extends Vue {
  @Prop() value!: NumbersFilterModel;
  @Prop({ default: false }) readonly!: boolean;
  @Prop() filterId!: FilterId;

  readonly excludedOperations = [EventOperation.LIKE, EventOperation.NOT_LIKE];
  readonly rules = [ValidUtil.required(this.$lang("validation.required"))];

  get localValue(): NumbersFilterModel {
    return this.value;
  }

  get localValueRange(): RangeModel {
    return this.localValue.range;
  }

  set localValueRange(value: RangeModel) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.range = value;
    this.$emit("input", clonedLocalValue);
  }
}
