















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import SegmentModel, {
  SegmentProvidedType,
} from "@/segments/models/SegmentModel";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component
export default class ProvidedInfo extends Vue {
  @Prop() applicationId!: string;
  @Prop() value!: SegmentModel;
  @Prop({ default: false }) readonly!: boolean;

  file: File | null = null;
  providedType = SegmentProvidedType.ADJUST_IDS;
  readonly rules = [ValidUtil.required(this.$lang("validation.required"))];

  get providedTypes() {
    return [
      SegmentProvidedType.ADJUST_IDS,
      // SegmentProvidedType.CDS_IDS, not supported
    ].map((it) => {
      return {
        name: this.$lang(`segment.provided.type.${it.toLowerCase()}`),
        value: it,
      };
    });
  }

  get fileInfo(): string {
    return {
      ADJUST_IDS: this.$lang("segment.provided.adjustIdFileInfo"),
      CDS_IDS: "",
    }[this.providedType];
  }

  @Watch("value.file", { immediate: true })
  private watchValue() {
    this.file = this.value.file || null;
  }

  @Watch("file")
  private watchFile() {
    this.$emit("input:file", this.file);
  }

  @Watch("providedType", { immediate: true })
  private watchProvidedType(newProvidedType: SegmentProvidedType) {
    this.value.providedType = newProvidedType;
  }
}
