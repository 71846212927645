






























































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { cloneDeep, debounce } from "lodash";

import SegmentModel, {
  SEGMENT_TYPE_TO_APP_SECTION,
  SegmentType,
} from "@/segments/models/SegmentModel";
import {
  ExternalTestGroupModel,
  ExternalTestModel,
} from "@/ab-tests/models/ExternalTest";
import AbTestConfigurationModel from "@/ab-tests/models/AbTestConfigurationModel";
import { AccessType, CurrentUserModel } from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component
export default class SegmentInfo extends Vue {
  @Prop() value!: SegmentModel;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) isEditPage!: boolean;
  @Prop({ default: true }) valid!: boolean;
  @Prop({ default: 0 }) filterLength!: number;
  @Prop({ default: 0 }) selectedListItem!: number;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly nameRules = [
    ValidUtil.required(this.$lang("validation.required")),
    ValidUtil.maxLength(50, this.$lang("shared.errors.maxCharacters", 50)),
    ValidUtil.endsWith(
      SegmentModel.AB_TEST_CONTROL_SEGMENT_POSTFIX,
      this.$lang(
        "shared.errors.shouldntEndsWith",
        SegmentModel.AB_TEST_CONTROL_SEGMENT_POSTFIX
      )
    ),
  ];
  readonly descriptionRule = [
    ValidUtil.maxLength(255, this.$lang("shared.errors.maxCharacters", 255)),
  ];

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get isAbTestOrExternalTest(): boolean {
    return [SegmentType.EXTERNAL_AB_TEST, SegmentType.AB_TEST].includes(
      this.value.type
    );
  }

  get isShowNextStepBtn(): boolean {
    return (
      !this.isEditPage &&
      this.selectedListItem === 0 &&
      !this.filterLength &&
      !!this.localValueName
    );
  }

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  get currentUser(): CurrentUserModel {
    return this.$store.state.userStore.currentUser;
  }

  get availableSegmentTypes(): Array<SegmentType> {
    return [
      SegmentType.CUSTOM_STATIC,
      SegmentType.CUSTOM_DYNAMIC,
      ...(this.isAbTestOrExternalTest
        ? [SegmentType.AB_TEST, SegmentType.EXTERNAL_AB_TEST]
        : []),
    ].filter((type) =>
      this.isEditPage
        ? this.currentUser.viewAccessEntities[this.applicationId]?.includes(
            SEGMENT_TYPE_TO_APP_SECTION[type]
          )
        : this.currentUser.createAccessEntities[this.applicationId]?.includes(
            SEGMENT_TYPE_TO_APP_SECTION[type]
          )
    );
  }

  get types(): Array<Record<string, string>> {
    return this.availableSegmentTypes.map((value) => ({
      name: this.$lang(`segment.type.${value.toLowerCase()}`),
      value,
    }));
  }

  get accessTypes(): Array<{ name: string; value: AccessType }> {
    return Object.values(AccessType).map((value) => ({
      name: this.$lang(`accessType.${value.toString().toLowerCase()}`),
      value,
    }));
  }

  get isSegmentNameUnique(): boolean {
    return this.$store.state.segmentStore.isSegmentNameUnique;
  }

  get nameErrorMessages(): Array<string> {
    if (!this.readonly && !this.isSegmentNameUnique) {
      return [this.$lang("shared.errors.notUnique")];
    }

    return [];
  }

  get localValueName(): string {
    return this.value?.name ?? "";
  }

  set localValueName(value: string) {
    const clonedValue = cloneDeep(this.value);

    clonedValue.name = value;
    this.$emit("input", clonedValue);
  }

  get localValueDescription(): string {
    return this.value?.description ?? "";
  }

  set localValueDescription(value: string) {
    const clonedValue = cloneDeep(this.value);

    clonedValue.description = value;
    this.$emit("input", clonedValue);
  }

  get localValueType(): SegmentType {
    return this.value.type;
  }

  set localValueType(value: SegmentType) {
    const clonedValue = cloneDeep(this.value);

    clonedValue.type = value;
    this.$emit("input", clonedValue);
  }

  get localValueAccessType(): AccessType {
    return this.value.accessType;
  }

  set localValueAccessType(value: AccessType) {
    const clonedValue = cloneDeep(this.value);

    clonedValue.accessType = value;
    this.$emit("input", clonedValue);
  }

  get externalTest(): ExternalTestModel {
    return this.$store.state.externalTestStore.test;
  }

  get abTest(): AbTestConfigurationModel {
    return this.$store.state.abTestConfig.abTestConfig;
  }

  get testName(): string {
    return this.value.type === SegmentType.AB_TEST
      ? this.abTest.name
      : this.externalTest?.name ?? "";
  }

  get isControl(): boolean {
    return this.value.type === SegmentType.AB_TEST
      ? this.abTest.controlGroupName === this.value.name
      : (this.externalTest?.testGroups.find(
          (item: ExternalTestGroupModel) => item.segmentName === this.value.name
        )?.control as boolean);
  }

  get linkForTest(): Record<string, any> {
    return this.value.type === SegmentType.AB_TEST
      ? {
          name: "abTest_view",
          params: {
            configId: this.abTest?.id,
          },
        }
      : {
          name: "external_test",
          params: {
            configId: this.externalTest?.id,
          },
        };
  }

  @Watch("localValueName", { immediate: true })
  watchName = debounce(() => {
    if (!this.readonly) {
      this.$store.dispatch("checkIsSegmentNameUnique", this.value);
    }
  }, 500);

  @Watch("localValueType", { immediate: true })
  watchType(type: SegmentType) {
    if (this.availableSegmentTypes.includes(type)) {
      return;
    }

    this.localValueType = this.availableSegmentTypes[0];
  }
}
