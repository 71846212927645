




























































































































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import SegmentInfo from "./SegmentInfo.vue";
import SegmentFilters from "./SegmentFilters.vue";
import ProvidedInfo from "./ProvidedInfo.vue";
import SegmentModel, { SegmentType } from "@/segments/models/SegmentModel";
import { FilterModel, MenuItems } from "@/shared/models";
import { VueForm } from "@/shared/types/ExtendedVueType";

@Component({
  components: {
    SegmentInfo,
    SegmentFilters,
    ProvidedInfo,
  },
})
export default class SegmentForm extends Vue {
  @Prop() value!: SegmentModel;
  @Prop({ default: false }) isEditPage!: boolean;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) hasUnsavedChanges!: boolean;

  @Ref("form") form!: VueForm;

  selectedListItem = 0;
  valid = true;
  localValueFilter: Array<FilterModel> = [];

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get segmentName(): string {
    return this.value?.name ?? "";
  }

  get items(): Array<Record<string, any>> {
    return [
      {
        text: this.$lang("segment.tabs.info"),
        icon: "mdi-information-outline",
      },
      ...(this.isCustom
        ? [
            {
              text: this.$lang("segment.tabs.filters"),
              icon: "mdi-filter-cog-outline",
            },
          ]
        : []),
      ...(this.isProvided
        ? [
            {
              text: this.$lang("segment.tabs.provided_file"),
              icon: "mdi-filter-cog-outline",
            },
          ]
        : []),
    ];
  }

  get formData(): Array<Record<string, any>> {
    return [
      {
        id: 0,
        title: this.$lang("segment.tabs.info"),
        subtitle: this.isAbTestOrExternalTest
          ? this.$lang("segment.infoAbTestSubtitle")
          : this.$lang("segment.infoSubtitle"),
      },
      ...(this.isCustom
        ? [
            {
              id: 1,
              title: this.$lang("segment.tabs.filters"),
              subtitle: this.$lang("segment.filtersSubtitle"),
            },
          ]
        : []),
      ...(this.isProvided
        ? [
            {
              id: 1,
              title: this.$lang("segment.tabs.provided_file"),
            },
          ]
        : []),
    ];
  }

  get isAbTestOrExternalTest(): boolean {
    return [SegmentType.EXTERNAL_AB_TEST, SegmentType.AB_TEST].includes(
      this.localValue.type
    );
  }

  get isCustom(): boolean {
    return [
      SegmentType.CUSTOM_STATIC,
      SegmentType.CUSTOM_DYNAMIC,
      SegmentType.EXTERNAL_AB_TEST,
    ].includes(this.localValue.type);
  }

  get isProvided(): boolean {
    return [SegmentType.PROVIDED].includes(this.localValue.type);
  }

  get formTitle(): string {
    return (
      this.formData.find((item) => item.id === this.selectedListItem)?.title ??
      ""
    );
  }

  get formSubtitle(): string {
    return (
      this.formData.find((item) => item.id === this.selectedListItem)
        ?.subtitle ?? ""
    );
  }

  get savingInProgress(): boolean {
    return this.$store.state.segmentStore.savingInProgress;
  }

  get localValue(): SegmentModel {
    return this.value;
  }

  set localValue(value: SegmentModel) {
    this.$emit("input", value);
  }

  @Watch("localValueFilter", { deep: true })
  watchLocalValueFilter(value: Array<FilterModel>) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.filter = value;
    this.$emit("input", clonedLocalValue);
  }

  mounted() {
    this.localValueFilter = cloneDeep(this.localValue.filter);
  }

  setProvidedFile(file: File) {
    this.localValue.file = file;
  }

  goBack() {
    this.$router.push({
      name: this.$route.params.route || MenuItems.SEGMENT,
      params: { id: this.applicationId },
    });
  }

  emitSave() {
    if (!this.form.validate()) {
      return;
    }

    this.$emit("save");
  }
}
