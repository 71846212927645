

















































































































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import AttributionDateFilter from "@/shared/components/filters/AttributionDateFilter.vue";
import GroupedFilter from "@/shared/components/filters/GroupedFilter.vue";
import AdRevenueFilter from "@/shared/components/filters/AdRevenueFilter.vue";
import IapRevenueFilter from "@/shared/components/filters/IapRevenueFilter.vue";
import TrackerFilter from "@/shared/components/filters/TrackerFilter.vue";
import SessionNumberFilter from "@/shared/components/filters/SessionNumberFilter.vue";
import RangeBasedMultipleEventParamFilter from "@/shared/components/filters/RangeBasedMultipleEventParamFilter.vue";
import ExcludableMultipleDictionaryFilter from "@/shared/components/filters/ExcludableMultipleDictionaryFilter.vue";
import EventParamDateFilter from "@/shared/components/filters/EventParamDateFilter.vue";
import UserAgeFilter from "@/shared/components/filters/UserAgeFilter.vue";
import SubscriptionRenewalsCountFilter from "@/shared/components/filters/SubscriptionRenewalsCountFilter.vue";
import ReportFilterCurrentStateItem from "@/reports/components/ReportFilterCurrentStateItem.vue";
import {
  FilterId,
  FilterModel,
  FilterPreviewId,
  FILTER_ID_TO_COMPONENT,
  FILTER_ID_TO_INIT_FUNCTION,
  FILTER_ID_TO_FILTER_PREVIEW_ID,
  SEGMENT_FILTER_ID,
  FILTER_ID_TO_DATA_TYPES,
  COMMON_FILTER_ID_TO_ITEM_FILTER,
  TrackerOrigin,
} from "@/shared/models";
import { VueAutocomplete } from "@/shared/types/ExtendedVueType";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({
  components: {
    AttributionDateFilter,
    GroupedFilter,
    AdRevenueFilter,
    IapRevenueFilter,
    TrackerFilter,
    SessionNumberFilter,
    RangeBasedMultipleEventParamFilter,
    ExcludableMultipleDictionaryFilter,
    EventParamDateFilter,
    UserAgeFilter,
    SubscriptionRenewalsCountFilter,
    ReportFilterCurrentStateItem,
  },
})
export default class SegmentFilters extends Vue {
  @Prop() value!: Array<FilterModel>;
  @Prop({ default: false }) readonly!: boolean;

  @Ref("filtersAutocomplete") readonly filtersAutocomplete!: VueAutocomplete;

  readonly eventFilterPreviewIds = [
    FilterPreviewId.GENERATED_EVENT,
    FilterPreviewId.EVENT,
  ];
  readonly requiredArrayRules = [
    ValidUtil.requiredArray(this.$lang("validation.required")),
  ];
  readonly filterIdToPreviewId = FILTER_ID_TO_FILTER_PREVIEW_ID;
  readonly filterIdToDataTypes = FILTER_ID_TO_DATA_TYPES;
  readonly filterIdToItemFilter = COMMON_FILTER_ID_TO_ITEM_FILTER;
  readonly origins = [TrackerOrigin.MARKETING_EVENTS];
  isShowFilters = false;
  openExpansionPanel = false;
  filterId: FilterId | null = null;
  openedPanels: Array<number> = [];

  get applicationId(): string {
    return this.$route.params.id;
  }

  get filterIds(): Array<Record<string, string>> {
    return SEGMENT_FILTER_ID.reduce(
      (result: Array<Record<string, string>>, filterId: FilterId) => {
        if (this.value.some((it) => it.id === filterId)) {
          return result;
        }

        return [
          ...result,
          {
            name: this.$lang(`shared.filters.${filterId.toLowerCase()}`),
            value: filterId,
          },
        ] as Array<Record<string, string>>;
      },
      [] as Array<Record<string, string>>
    );
  }

  get filterComponents(): Record<string, string> {
    return FILTER_ID_TO_COMPONENT;
  }

  get isAutofocusForFilterFields(): boolean {
    return !this.$route.query?.copy;
  }

  get localValue(): Array<FilterModel> {
    return this.value;
  }

  set localValue(value: Array<FilterModel>) {
    let clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue = value;
    this.$emit("input", clonedLocalValue);
  }

  getItemsFilter(filterId: FilterId) {
    return COMMON_FILTER_ID_TO_ITEM_FILTER.get(filterId);
  }

  getLabelByFilterId(filterId: FilterId) {
    return this.$lang(`shared.filters.${filterId.toLowerCase()}`);
  }

  handleShowFilters() {
    this.setIsShowFilters(true);
  }

  setIsShowFilters(value: boolean) {
    this.isShowFilters = value;
    this.filtersAutocomplete.isFocused = value;
    this.filtersAutocomplete.isMenuActive = value;
  }

  addFilter() {
    if (!this.filterId) {
      return;
    }

    this.value.unshift(FILTER_ID_TO_INIT_FUNCTION[this.filterId]());
    this.setIsShowFilters(false);
    this.openedPanels = [
      0,
      ...this.openedPanels.map((item: number) => item + 1),
    ];
  }

  removeFilter(id: FilterId) {
    const foundIndex = this.value.findIndex((it) => it.id === id);
    this.value.splice(
      this.value.findIndex((it) => it.id === id),
      1
    );
    this.filterId = null;

    this.openedPanels = this.openedPanels.reduce(
      (result: Array<number>, item: number) => {
        if (item < foundIndex) {
          return [...result, item];
        } else if (item === foundIndex) {
          return result;
        }

        return [...result, item - 1];
      },
      []
    );
  }
}
